import {
  BooleanInput,
  FormDataConsumer,
  Labeled,
  NumberInput,
  required,
  useTranslate,
} from "react-admin";
import { ConditionalInput } from "@/Components/ConditionalForm";
import { useFormContext } from "react-hook-form";
type IntegrationProps = {
  source: string;
  label: string;
};

const TargetDelay = ({ label }: IntegrationProps) => {
  const translate = useTranslate();
  const { setValue } = useFormContext();

  const toggleInput = (event: any) => {
    if (!event) {
      setValue("delay.delay_at_put", 0);
    }
  };
  return (
    <FormDataConsumer>
      {({ scopedFormData }) => {
        return (
          <>
            <BooleanInput
              label={label}
              source={"delay.use_delay"}
              defaultValue={false}
              onChange={toggleInput}
            />
            <ConditionalInput
              values={scopedFormData}
              validate={(values: any) => {
                if (!values) return false;
                return values?.delay?.use_delay === true;
              }}
            >
              <Labeled label={translate("resources.configuration.fields.target.delayInfo")}>
                <NumberInput
                  source={"delay.delay_at_put"}
                  label={translate("resources.configuration.fields.target.delayValue")}
                  validate={required()}
                  sx={{ minWidth: "33%" }}
                />
              </Labeled>
            </ConditionalInput>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export default TargetDelay;
