import {
  useTranslate,
  useListContext,
  useDataProvider,
  maxLength,
  SortPayload,
  Form,
  ReferenceInput,
  useResourceContext,
} from "react-admin";
import { Box } from "@mui/material";
import { useState } from "react";
import { useNotify } from "react-admin";
import { ExecutionFiles, FileMetaData } from "./FilesList";
import { useShareableState } from "./SharedState";
import { useBetween } from "use-between";
import { v4 as uuid } from "uuid";

import { authorizeAction } from "@/Settings/roles";
import { toUpperCase } from "@/Tools/helpers";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";
import {
  StyledTextInput,
  StyledButton,
  classes as InputClasses,
  StyledSelectInput,
  StyledDateTimeInput,
} from "@/Components/CustomStyled/Input";

export let showActionsButton = true;

export const setShowActionsButton = (action: boolean = false) => {
  showActionsButton = action;
};

interface RetryQuery {
  correlation_id: string;
  start_date_timestamp: string;
  files: SelectedFileForAction[];
}

interface FilesData {
  executionStartDateTimestamp: number;
  files: SelectedFileForAction[];
}

interface SelectedFileForAction {
  fileName: string;
  targets: number[];
}

const ActionsButton = () => {
  const { listChecked } = useBetween(useShareableState);

  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const dataProvider = useDataProvider();

  const handleClick = (actionType: "retry" | "abandon") => {
    const url = `tracking/${actionType}`;
    const query = (): RetryQuery[] => {
      const query: RetryQuery[] = [];
      const processedCorrelationId: string[] = [];
      ExecutionFiles.forEach((execution: FileMetaData[]) => {
        const files: Map<string, FilesData> = new Map<string, FilesData>();

        execution
          .filter((file: FileMetaData) => listChecked.includes(file.fileId))
          ?.forEach((file: FileMetaData) => {
            const selectedFiles = files.get(file.executionCorrelationId)?.files ?? [];

            selectedFiles.push({
              fileName: file.fileName,
              targets: file.targets,
            });

            files.set(file.executionCorrelationId, {
              executionStartDateTimestamp: file.executionStartDateTimestamp,
              files: selectedFiles,
            });
          });

        if (files.size > 0) {
          files.forEach((filesData: FilesData, correlationId: string) => {
            // enforce the check of duplicated correlation ID
            if (!processedCorrelationId.includes(correlationId)) {
              query.push({
                correlation_id: correlationId,
                start_date_timestamp: filesData.executionStartDateTimestamp.toString(),
                files: filesData.files,
              });
              processedCorrelationId.push(correlationId);
            }
          });
        }
      });
      return query;
    };
    const httpQuery = query();
    if (httpQuery.length === 0) {
      notify("resources.tracking.actions.actionNoSeletion", { type: "warning" });
      return;
    }

    setLoading(true);
    dataProvider
      .create(url, { data: httpQuery })
      .then(() => {
        notify("resources.tracking.actions.actionSuccess", { type: "success" });
      })
      .catch((e) => {
        notify("resources.tracking.actions.actionError", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return listChecked.length > 0 ? (
    <StyledContainer className={classes.searchBarActions}>
      <StyledButton
        className={InputClasses.searchFormSubmit}
        variant="contained"
        color="secondary"
        type="submit"
        size="small"
        disabled={loading}
        onClick={() => handleClick("retry")}
      >
        <>Retry</>
      </StyledButton>

      <StyledButton
        className={InputClasses.searchFormSubmit}
        variant="contained"
        color="secondary"
        type="submit"
        size="small"
        disabled={loading}
        onClick={() => handleClick("abandon")}
      >
        <>Abandon</>
      </StyledButton>
    </StyledContainer>
  ) : (
    <></>
  );
};

const SearchBarForm = (props: any) => {
  const resource = useResourceContext()!;
  const { updateListChecked } = useBetween(useShareableState);
  const translate = useTranslate();
  const { filterValues, setFilters, displayedFilters } = useListContext();

  const yesterdayDateAt17 = new Date();
  yesterdayDateAt17.setDate(yesterdayDateAt17.getDate() - 1);
  yesterdayDateAt17.setHours(17, 0, 0);

  const onSubmit = (values: any) => {
    updateListChecked([]);
    values.searchId = uuid();
    setFilters(values, displayedFilters);
  };
  const sortable: SortPayload = { field: "name", order: "ASC" };

  return (
    <Box width="100%">
      <StyledContainer className={classes.searchForm}>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Box>
            <StyledTextInput
              resettable
              className={InputClasses.searchFormText}
              helperText={false}
              validate={[maxLength(4)]}
              parse={toUpperCase}
              source="configuration_name"
              label={translate("resources.tracking.searchForm.configurationName")}
            />
            <ReferenceInput
              source="source_application_id"
              reference="application"
              className={InputClasses.searchFormReference}
              label={translate("resources.tracking.searchForm.issuingApplication")}
              allowEmpty={true}
              sort={sortable}
            >
              <StyledSelectInput
                optionText="name"
                className={InputClasses.searchFormSelect}
                sx={{ minWidth: "215px" }}
              />
            </ReferenceInput>
            <ReferenceInput
              source="source_connexion_id"
              reference="connection"
              className={InputClasses.searchFormReference}
              label={translate("resources.tracking.searchForm.issuingConnection")}
              allowEmpty={true}
              sort={sortable}
            >
              <StyledSelectInput
                optionText="name"
                className={InputClasses.searchFormSelect}
                sx={{ minWidth: "215px" }}
              />
            </ReferenceInput>
            <StyledDateTimeInput
              defaultValue={yesterdayDateAt17}
              className={InputClasses.searchFormDatePicker}
              label={translate("resources.tracking.searchForm.executionStartDate")}
              source="executionStartDate"
            />
            <StyledSelectInput
              source="flowStatus"
              label={translate("resources.tracking.searchForm.flowStatus")}
              className={InputClasses.searchFormSelect}
              sx={{ minWidth: "215px" }}
              choices={[
                {
                  id: "all",
                  name: translate("resources.tracking.searchForm.flowStatusOptions.all"),
                },
                {
                  id: "completed",
                  name: translate("resources.tracking.searchForm.flowStatusOptions.completed"),
                },
                {
                  id: "progress",
                  name: translate("resources.tracking.searchForm.flowStatusOptions.progress"),
                },
                {
                  id: "error",
                  name: translate("resources.tracking.searchForm.flowStatusOptions.error"),
                },
                {
                  id: "waiting",
                  name: translate("resources.tracking.searchForm.flowStatusOptions.waiting"),
                },
                {
                  id: "abandoned",
                  name: translate("resources.tracking.searchForm.flowStatusOptions.abandoned"),
                },
                {
                  id: "cancelled",
                  name: translate("resources.tracking.searchForm.flowStatusOptions.cancelled"),
                },
                {
                  id: "allExceptCompleted",
                  name: translate(
                    "resources.tracking.searchForm.flowStatusOptions.allExceptCompleted"
                  ),
                },
              ]}
            />
          </Box>
          <Box>
            <StyledTextInput
              resettable
              className={InputClasses.searchFormText}
              helperText={false}
              validate={[maxLength(6)]}
              parse={toUpperCase}
              source="configuration_code"
              label={translate("resources.tracking.searchForm.configurationCode")}
            />
            <ReferenceInput
              source="target_application_id"
              reference="application"
              className={InputClasses.searchFormReference}
              label={translate("resources.tracking.searchForm.receivingApplication")}
              allowEmpty={true}
              sort={sortable}
            >
              <StyledSelectInput
                optionText="name"
                className={InputClasses.searchFormSelect}
                sx={{ minWidth: "215px" }}
              />
            </ReferenceInput>
            <ReferenceInput
              source="target_connexion_id"
              reference="connection"
              className={InputClasses.searchFormReference}
              label={translate("resources.tracking.searchForm.receivingConnection")}
              allowEmpty={true}
              sort={sortable}
            >
              <StyledSelectInput
                optionText="name"
                className={InputClasses.searchFormSelect}
                sx={{ minWidth: "215px" }}
              />
            </ReferenceInput>
            <StyledDateTimeInput
              className={InputClasses.searchFormDatePicker}
              label={translate("resources.tracking.searchForm.executionEndDate")}
              source="executionEndDate"
            />
            <StyledButton
              className={InputClasses.searchFormSubmit}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              label={translate("resources.tracking.searchForm.submitButton")}
            />
          </Box>
        </Form>
      </StyledContainer>
      {authorizeAction(props.permissions, resource, "retry") ? <ActionsButton /> : null}
    </Box>
  );
};

export default SearchBarForm;
