import { ConditionalInput } from "@/Components/ConditionalForm";
import {
  BooleanInput,
  required,
  RadioButtonGroupInput,
  TextInput,
  useTranslate,
} from "react-admin";
import { InputLabel } from "@mui/material";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";

type FilterProps = {
  disabled?: boolean;
};

const FiltersSource = ({ disabled = false }: FilterProps) => {
  const translate = useTranslate();
  return (
    <StyledContainer className={classes.filterSource}>
      <BooleanInput
        source="source.filters_enabled"
        defaultValue={false}
        label={translate("resources.configuration.fields.source_filters")}
        disabled={disabled}
      />
      <ConditionalInput validate={(values: any) => values?.source?.filters_enabled === true}>
        <InputLabel>{translate("resources.configuration.fields.source_filters_update")}</InputLabel>
        <RadioButtonGroupInput
          label={translate("resources.configuration.fields.source_filters_unitType")}
          source="source.filters.unitType"
          choices={[
            { id: "days", name: "Days" },
            { id: "hours", name: "Hours" },
          ]}
          disabled={disabled}
        />
        <TextInput
          sx={{ marginLeft: "12px" }}
          source="source.filters.unit"
          validate={[required()]}
          label={translate("resources.configuration.fields.source_filters_unit")}
          disabled={disabled}
        />
      </ConditionalInput>
    </StyledContainer>
  );
};

export default FiltersSource;
