import {TextField, BooleanField, DateField, ReferenceField, ArrayField, Datagrid, RichTextField} from "react-admin";
import { ConnectionTitle } from ".";
import ShowBase from "../BaseModel/Show";
import { ParametersFields } from "./Parameters";

const ShowForm = (props: any) => (
  <ShowBase {...props} title={<ConnectionTitle />}>
    <BooleanField source="enabled" />
    <ReferenceField source="type" reference="connectionType" link="show">
      <TextField source="name" />
    </ReferenceField>
    <ParametersFields/>
    <ReferenceField source="created_by" reference="user" link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="created_at" showTime={true} />
    <ReferenceField source="updated_by" reference="user" link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="updated_at" showTime={true} />
    <BooleanField source="maintenance_enabled" />
    <ArrayField source="maintenance_schedules">
        <Datagrid rowClick={false} bulkActionButtons={false}>
          <TextField source="start_schedule" />
          <TextField source="end_schedule" />
        </Datagrid>
    </ArrayField>
      <RichTextField source="retry.retryDelay" />
      <RichTextField source="retry.retryLimit" />
  </ShowBase>
);

export default ShowForm;
