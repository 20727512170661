import {
  TextField,
  List,
  Datagrid,
  useTranslate,
  UrlField,
  usePermissions,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import SearchBarForm from "./SearchBar";
import FileList, { disabledSelectionFilesStatus, ExecutionFiles } from "./FilesList";
import Checkbox from "@mui/material/Checkbox";
import OpenLogsButton from "../../CustomOpenLogsAction/OpenLogsAction";
import { useSharedListChecked } from "@/Components/Models/Tracking/SharedState";
import { authorizeAction } from "@/Settings/roles";
import { StyledNumberField, classes as FieldClasses } from "@/Components/CustomStyled/Fields";

const NumberFieldColorCondition = (data: any) => {
  const record = useRecordContext<Record<string, any>>();
  return (
    <StyledNumberField
      {...data}
      className={record?.[data?.source] > 0 ? data?.className : FieldClasses.nocolor}
    />
  );
};

const ConfigurationLink = (data: any) => {
  const record = useRecordContext<Record<string, any>>();
  const link = "/#/configuration/" + record?.configurationId;
  return (
    <UrlField {...data} href={link} target="_blank" rel="noopener noreferrer">
      {data.source}
    </UrlField>
  );
};

const ExecutionCheckBox = (data: any) => {
  const record = useRecordContext<Record<string, any>>();
  const { listChecked, updateListChecked } = useSharedListChecked();

  const ExecutionSelection = (event: any, executionId: string) => {
    const fileMetaDataArray = ExecutionFiles.get(executionId)?.filter(
      (file) => !disabledSelectionFilesStatus.includes(file.fileStatus)
    );
    const ids = fileMetaDataArray?.map((file) => file.fileId) || [];

    if (event.target.checked) {
      updateListChecked([...listChecked, ...ids]);
    } else {
      updateListChecked(listChecked.filter((element) => !ids.includes(element)));
    }
  };
  return (
    <Checkbox
      disabled={record?.mergedFiles?.length === 0}
      id={record?.id}
      onChange={(e) => ExecutionSelection(e, record?.id)}
    />
  );
};

const ListForm = (props: any) => {
  const resource = useResourceContext()!;
  const translate = useTranslate();
  const { permissions, isLoading } = usePermissions();
  return isLoading ? (
    <p>Loading</p>
  ) : (
    <List
      {...props}
      pagination={false}
      sort={{ field: "endDateLastFlow", order: "DESC" }}
      actions={<SearchBarForm permissions={permissions} />}
    >
      <Datagrid expand={FileList} rowClick={false} bulkActionButtons={false}>
        <ExecutionCheckBox></ExecutionCheckBox>
        <ConfigurationLink
          source="configurationName"
          label={translate("resources.tracking.searchForm.results.configurationName")}
        />
        <ConfigurationLink
          source="configurationCode"
          label={translate("resources.tracking.searchForm.results.configurationCode")}
        />
        <NumberFieldColorCondition
          align="center"
          className={FieldClasses.green}
          source="terminatedInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={FieldClasses.red}
          source="errorInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={FieldClasses.orange}
          source="InProgressInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={FieldClasses.lightblue}
          source="InQueueInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={FieldClasses.yellow}
          source="abandonedInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={FieldClasses.darkgray}
          source="cancelledInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={FieldClasses.orange}
          source="pendingFiles"
        />
        <TextField align="center" source="waitingTimeInProgressFiles" />
        <TextField align="left" source="endDateLastFlow" />
        {authorizeAction(permissions, resource, "log") ? <OpenLogsButton /> : <></>}
      </Datagrid>
    </List>
  );
};

export default ListForm;
