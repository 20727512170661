import {
  useTranslate,
  useListContext,
  CreateButton,
  ExportButton,
  usePermissions,
  Loading,
  SortPayload,
  Form,
  useResourceContext,
} from "react-admin";
import { Box } from "@mui/material";

import { v4 as uuid } from "uuid";
import { authorizeAction } from "@/Settings/roles";
import { toUpperCase } from "@/Tools/helpers";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";
import {
  StyledTextInput,
  classes as InputClasses,
  StyledReferenceInput,
  StyledButton,
  StyledNullableBooleanInput,
  StyledSelectInput,
} from "@/Components/CustomStyled/Input";

export let showActionsButton = true;

export const setShowActionsButton = (action: boolean = false) => {
  showActionsButton = action;
};

const CustomSearchBarForm = () => {
  const resource = useResourceContext();
  const { isLoading, permissions } = usePermissions();
  const translate = useTranslate();
  const { filterValues, displayedFilters, setFilters } = useListContext();

  const onSubmit = (values: any) => {
    values.searchId = uuid();
    if (values.targets && Object.keys(values.targets).length === 0) {
      delete values.targets;
    }
    setFilters(values, displayedFilters);
  };
  const sortable: SortPayload = { field: "name", order: "ASC" };

  return isLoading ? (
    <Loading />
  ) : (
    <Box width="100%">
      <div style={{ textAlign: "left", paddingTop: "1rem" }}>
        {authorizeAction(permissions, resource!, "create") && <CreateButton />}
        <ExportButton />
      </div>
      <StyledContainer className={classes.searchForm}>
        <Form onSubmit={onSubmit} defaultValues={filterValues} mode="onBlur">
          <Box>
            <StyledTextInput
              resettable
              className={InputClasses.searchFormText}
              helperText={false}
              source="name"
              parse={toUpperCase}
              label={translate("search.filter.name")}
            />
            <StyledReferenceInput
              source="source.application"
              reference="application"
              className={InputClasses.searchFormSelect}
              label={translate("resources.configuration.fields.source_application")}
              allowEmpty={true}
              sort={sortable}
            >
              <StyledSelectInput
                className={InputClasses.searchFormSelect}
                optionText="name"
                sx={{ minWidth: "215px" }}
              />
            </StyledReferenceInput>
            <StyledReferenceInput
              source="source.connection"
              reference="connection"
              className={InputClasses.searchFormSelect}
              label={translate("resources.configuration.fields.source_connection_list")}
              allowEmpty={true}
              sort={sortable}
            >
              <StyledSelectInput
                className={InputClasses.searchFormSelect}
                optionText="name"
                sx={{ minWidth: "215px" }}
              />
            </StyledReferenceInput>
            <StyledNullableBooleanInput
              className={InputClasses.searchFormNullableBoolean}
              helperText={false}
              source="enabled"
              sx={{ minWidth: "215px" }}
            />
          </Box>
          <Box>
            <StyledTextInput
              resettable
              className={InputClasses.searchFormText}
              helperText={false}
              source="code"
              parse={toUpperCase}
              label={translate("search.filter.code")}
            />
            <StyledReferenceInput
              source="targets.application"
              reference="application"
              className={InputClasses.searchFormSelect}
              label={translate("resources.configuration.fields.target_application")}
              allowEmpty={true}
              sort={sortable}
            >
              <StyledSelectInput
                className={InputClasses.searchFormSelect}
                optionText="name"
                sx={{ minWidth: "215px" }}
              />
            </StyledReferenceInput>
            <StyledReferenceInput
              source="targets.connection"
              reference="connection"
              className={InputClasses.searchFormSelect}
              label={translate("resources.configuration.fields.target_connection_list")}
              allowEmpty={true}
              sort={sortable}
            >
              <StyledSelectInput
                className={InputClasses.searchFormSelect}
                optionText="name"
                sx={{ minWidth: "215px" }}
              />
            </StyledReferenceInput>
            <StyledButton
              className={InputClasses.searchFormSubmit}
              variant="contained"
              type="submit"
              size="large"
              label={translate("resources.tracking.searchForm.submitButton")}
            />
          </Box>
        </Form>
      </StyledContainer>
    </Box>
  );
};

export default CustomSearchBarForm;
