import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    settings: "Settings",
    language: "Language",
    logout: "Logout",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      welcome: {
        title: "Welcome to the Arkema MFT administration",
        subtitle: "Use the menu on the left to navigate through the administration.",
      },
    },
    menu: {
      sales: "Sales",
      catalog: "Catalog",
      customers: "Customers",
    },
  },
  common: {
    actions: {
      enable: {
        "btn.enabled": "Enabled",
        "btn.disabled": "Disabled",
        title: "Validate",
        content: "Are you sure you want to change status for these items?",
        success: "Status changed for %{resource} items",
        failure: "Status change failed for %{resource} items",
      },
      copyToEnv: {
        btn: "Copy to",
        success: "Copy successful for %{resource} items",
        failure: "Copy failed : %{error}",
      },
      explore: {
        name: "Name",
        size: "Size",
        type: "Type",
        modifiedAt: "Last modification",
      },
      test: {
        label: "Test",
        success:
          "Test successfully for %{id} the connectivity test is (%{connectivityTest}) and data exchange is  (%{listingFile})",
        failure: "Error during test for %{id} (%{error})",
        timeout: "Request timed out after 30 seconds",
      },
      overview: "Overview",
      copy: "Copy",
      yes: "Yes",
      no: "No",
    },
  },
  search: {
    filter: {
      name: "Name",
      description: "Description",
      code: "Code",
      enabled: "Enabled",
    },
  },
  resources: {
    authType: {
      name: "Auth Type",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    userRolesType: {
      name: "User Roles",
      fields: {
        name: "Role",
        description: "Description",
      },
    },
    connectionType: {
      name: "Connection Type",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    transfertType: {
      name: "Transfert Type",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    transfertMode: {
      name: "Transfert Mode",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    criticityType: {
      name: "Criticity Type",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    pollingType: {
      name: "Polling Type",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    fileNamePattern: {
      name: "File Name Pattern",
      fields: {
        name: "Name",
        description: "Description",
        pattern_rule: "Pattern Rule",
        trim_character: "Trim Character",
        new_name: "New name",
      },
    },
    transformationType: {
      name: "Transformation Type",
      fields: {
        name: "Name",
        description: "Description",
        parameters: "Parameters",
      },
    },
    integrationType: {
      name: "Integration Type",
      fields: {
        name: "Name",
        description: "Description",
        command: "Command",
        default_parameters: "Default Parameters",
        default_path: "Default Path",
      },
    },
    application: {
      name: "Application",
      fields: {
        name: "Name",
        description: "Description",
      },
      deleteActions: {
        allDeleted: "All selected applications are deleted",
        unDeleted:
          "%{deletedCount} applications are deleted, %{undeletedCount} applications are ignored (which are associated with configurations)",
        error: "Error when deleting applications",
        deleteLabel: "DELETE",
        deletingLabel: "DELETING...",
      },
    },
    connection: {
      name: "Connection",
      fields: {
        name: "Name",
        description: "Description",
        host: "Host",
        type: "Type",
        user: "User",
        password: "Password",
        reject_unauthorized: "Reject unauthorized certificates",
        privateKey: "Private Key",
        passphrase: "Passphrase",
        port: "Port",
        bucketName: "S3 Bucket",
        roleARN: "Role ARN",
        roleSessionDurationInSec: "Role session duration in sec",
        region: "AWS Region",
        enabled: "Enabled",
        created_by: "Created By",
        created_at: "Created At",
        updated_by: "Updated By",
        updated_at: "Updated At",
        maintenance_enabled: "All maintenance enabled",
        mainetance_start_schedule_expression: "Start Schedule Expression",
        mainetance_end_schedule_expression: "End Schedule Expression",
        maintenance_note_utc: "Note: Cron expressions should be projected to UTC times",
        transfert_path: "MOVE_AFTER path",
      },
      deleteActions: {
        allDeleted: "All selected connections are deleted",
        unDeleted:
          "%{deletedCount} connections are deleted, %{undeletedCount} connections are ignored (which are associated with configurations)",
        error: "Error when deleting connections",
        deleteLabel: "DELETE",
        deletingLabel: "DELETING...",
      },
      errors: {
        privateKey_or_password_required: "Private Key or Password required",
      },
    },
    user: {
      name: "User",
      fields: {
        name: "Name",
        email: "Email",
        roles: "Current Roles",
        updateRoles: "New Roles",
      },
      edit: {
        roleLengthError: "Assign maximum one (1) role",
      },
    },
    tracking: {
      name: "Tracking console",
      actions: {
        actionSuccess: "Opeation success",
        actionError: "Opeation failed",
        actionNoSeletion: "No files selected yet !",
      },
      files: {
        action: "Action",
        fileName: "File name",
        status: "MFT status",
        executionStartDate: "Execution start date",
        executionEndDate: "Execution end date",
        errorDetails: "Error details",
        accessLogs: "Go to logs",
        targetName: "Target Name",
        targetConnexionName: "Target connection name",
        allFilesRetrieved: "All files executions has been retrieved",
        downloadFile: "Download file",
      },
      bulks: {
        resetButton: "Reset",
        abandonmentButton: "Abandonment",
        retryButton: "Retry",
      },
      searchForm: {
        all: "All",
        configurationName: "Flow name",
        configurationCode: "Flow code ",
        issuingApplication: "Issuing application",
        receivingApplication: "Receiving application",
        executionStartDate: "Execution start date",
        issuingConnection: "Issuing Connection",
        receivingConnection: "Receiving Connection",
        executionEndDate: "Execution end date",
        flowStatus: "Flow status",
        submitButton: "Search",
        flowStatusOptions: {
          all: "all",
          completed: "Completed",
          progress: "In progress",
          error: "In error ",
          abandoned: "Abandoned",
          cancelled: "Cancelled",
          allExceptCompleted: "All except completed",
          waiting: "Waiting",
        },
        results: {
          configurationName: "Name",
          configurationCode: "Code",
          editExecution: "Edit",
        },
      },
    },
    incomingHttpCredentials: {
      name: "Incoming HTTP Credentials",
      fields: {
        name: "Name",
        username: "User",
        password: "Password",
        authType: "Auth Type",
        description: "Description",
        created_by: "Created By",
        created_at: "Created At",
        updated_by: "Updated By",
        updated_at: "Updated At",
        passwordError:
          "The password must be at least 10 characters including at least 1 uppercase letter, 1 lowercase letter, 1 special character (one of [!@#$%^&*()_\\-+=] ) and 1 digit",
      },
    },
    softlock: {
      name: "Softlock",
      fields: {
        id: "ID",
        mode: "Mode",
        start_date: "Start Date",
      },
    },
    polling: {
      name: "Polling",
      fields: {
        name: "Name",
        description: "Description",
        enabled: "Enabled",
        connection: "Connection",
        schedule_expression: "Schedule Expression",
        regex: "Regex",
        path: "Path",
        created_by: "Created By",
        created_at: "Created At",
        updated_by: "Updated By",
        updated_at: "Updated At",
        connection_list: "Connection",
      },
    },
    integrationConfiguration: {
      name: "Integration",
      fields: {
        name: "Name",
        description: "Description",
        type: "Integration Type",
        parameters: {
          connection: "Connection",
          path: "Path",
          file_name: "Filename",
          content_pattern: "Content Pattern",
          flow_id: "Configuration Id",
        },
        created_by: "Created By",
        created_at: "Created At",
        updated_by: "Updated By",
        updated_at: "Updated At",
      },
      infoWriteFile:
        "Available placeholders: %{date_iso}, %{date}, %{time}, %{timestamp}, %{date_format}, %{files}, %{correlation_id}",
      infoDisabled: "Integrations template disabled for this type",
    },
    configuration: {
      name: "Configuration",
      fields: {
        id: "Configuration ID",
        name: "Name",
        code: "Code",
        direct: "Direct",
        description: "Description",
        criticity: "Criticity",
        requestor: "Requestor",
        demand_number: "Demand Number",
        enabled: "Enabled",
        created_by: "Created By",
        created_at: "Created At",
        retry: "Retry",
        retry_auto_frequence: "Retry limit",
        retry_auto_ecart: "Retry delay in milliseconds",
        updated_by: "Updated By",
        updated_at: "Updated At",
        transformation: "Transformation",
        "source.use_s3_deposit": "Use S3 file deposit",
        "source.description": "Source Description",
        "source.connection": "Connection",
        "source.enabled": "Enabled",
        "source.wisp_group": "WISP Group",
        "source.path": "Path",
        "source.application": "Application",
        "source.regex": "Regex",
        "source.transfert_type": "Transfert Type",
        "source.transfert_mode": "Transfert Mode",
        "source.nb_files_per_transfert": "Nb Files Per Transfert",
        "source.schedule_expression": "Schedule Expression",
        "source.polling": "Polling Type",
        "source.transformations": "Transformations",
        "source.job": "Job",
        "source.information": "Information",
        "source.transfert_path": "Transfert Path (only for MOVE_AFTER)",
        source_connection_list: "Source - Connection",
        source_enabled_list: "Source - Enabled",
        source_application: "Source - Application",
        source_path: "Source - Path",
        source_filters: "Filters",
        source_filters_unit: "Unit",
        source_filters_unitType: "Unit Type",
        source_filters_update_date_days: "Days",
        source_filters_update_date_hours: "Hours",
        source_filters_update: "Filters files which the updated date is before : ",
        "target.description": "Target Description",
        "target.wisp_group": "WISP Group",
        "target.connection": "Connection",
        "target.enabled": "Enabled",
        "target.error_if_file_exists": "Raise an error if file already exists",
        "target.path": "Path",
        "target.application": "Application",
        "target.pattern_file_name": "Pattern File Name",
        "target.nb_files_per_transfert": "Nb Files Per Transfert",
        "target.delay": "Transfer Delay",
        "target.delayInfo": "Delay (in seconds)",
        "target.delayValue": "Delay value",
        "target.schedule_expression": "Schedule Expression",
        "target.integration": "Integration",
        "target.integrationTemplate": "Integration Template",
        "target.integrationType": "Integration Type",
        "target.integrationFlowId": "Integration Flow Id",
        "target.integrationPath": "Integration Path",
        "target.integrationFilename": "Integration Filename",
        "target.integrationContentPattern": "Integration Content Pattern",
        "target.integrationConnection": "Integration Connection",
        "target.integrationUseCurrentConnection": "Use current target connection",
        "target.transfer_type": "Transfer type",
        "target.transformations": "Transformations",
        target_connection_list: "Target - Connection",
        target_enabled_list: "Target - Enabled",
        target_application: "Target - Application",
        target_path: "Target - Path",
        "target.job": "Job",
        "target.information": "Information",
        "target.autoDisabled": "Auto Disabled",
        "target.new_name": "New name",
        "target.trim_character": "Trim character",
        filename_error: `The filename should not contain any of the following characters : <, >, :, |, ?, *, ", /, \\`,
      },
    },
  },
};

export default customEnglishMessages;
