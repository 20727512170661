import {
  TextField,
  ReferenceField,
  BooleanField,
  useTranslate,
  ArrayField,
  Datagrid,
  usePermissions,
  Loading,
  useRecordContext,
} from "react-admin";
import ListBase from "../BaseModel/List";
import OpenWindowButton from "@/Components/CustomOpenWindowAction/OpenWindowAction";
import CustomSearchBarForm from "./CustomSearchBar";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { accessMenu } from "@/Settings/roles";

const ListPanel = () => {
  const record = useRecordContext();
  const html = `
        <h1>Description</h1>
        <p>${record?.description}</p>
        <h1>Source</h1>
        <p>${record?.source.description}</p>
        <h1>Targets</h1>
        ${record?.targets.map((target: any) => `${target.description}`).join("\n")}
      `;
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
};

const ListForm = (props: any) => {
  const { permissions, isLoading } = usePermissions();
  const translate = useTranslate();

  return isLoading ? (
    <Loading />
  ) : (
    <ListBase
      disableSyncWithStore={true}
      actions={<CustomSearchBarForm />}
      listpanel={<ListPanel />}
      {...props}
    >
      <TextField source="code" />
      <BooleanField source="enabled" sortable={false} />
      <TextField
        source="source.application"
        label={translate("resources.configuration.fields.source_application")}
      />
      <TextField
        source="source.path"
        label={translate("resources.configuration.fields.source_path")}
      />
      <TextField source="source.regex" />
      <ReferenceField
        source="source.connection"
        reference="connection"
        label={translate("resources.configuration.fields.source_connection_list")}
        link={accessMenu(permissions, "connection") ? "show" : false}
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField
        source="source.enabled"
        label={translate("resources.configuration.fields.source_enabled_list")}
        sortable={false}
      />

      <TargetField label={translate("resources.configuration.fields.target_connection_list")}>
        <ReferenceField
          source="targets[0].connection"
          reference="connection"
          label={translate("resources.configuration.fields.target_connection_list")}
          link={accessMenu(permissions, "connection") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>
      </TargetField>
      <TargetField label={translate("resources.configuration.fields.target_application")}>
        <TextField
          source="targets[0].application"
          label={translate("resources.configuration.fields.target_application")}
        />
      </TargetField>
      <TargetField label={translate("resources.configuration.fields.target_path")}>
        <TextField
          source="targets[0].path"
          label={translate("resources.configuration.fields.target_path")}
        />
      </TargetField>
      <TargetField label={translate("resources.configuration.fields.target_enabled_list")}>
        <BooleanField
          source="targets[0].enabled"
          sortable={false}
          label={translate("resources.configuration.fields.target_enabled_list")}
        />
      </TargetField>

      <TargetSection translate={translate} label={"Multi Targets"} permissions={permissions} />

      <OpenWindowButton label="common.actions.overview" />
    </ListBase>
  );
};

const DataGridHeader = (props: any) => {
  return (
    <TableHead>
      <TableRow>
        {props.children.map((child: any) => {
          return <TableCell key={child.props.source}>{child.props.label}</TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
};

const TargetSection = (props: any) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const multi = record.targets.length > 1;
  return multi ? (
    <ArrayField source="targets" label={"Targets"}>
      <Datagrid
        header={<DataGridHeader />}
        rowClick={false}
        bulkActionButtons={false}
        isRowSelectable={() => false}
      >
        <ReferenceField
          source="connection"
          reference="connection"
          link={accessMenu(props.permissions, "connection") ? "show" : false}
          label={props.translate("resources.configuration.fields.target_connection_list")}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField
          source="application"
          label={props.translate("resources.configuration.fields.target_application")}
        />
        <TextField
          source="path"
          label={props.translate("resources.configuration.fields.target_path")}
        />
        <BooleanField
          source="enabled"
          sortable={false}
          label={props.translate("resources.configuration.fields.target_enabled_list")}
        />
      </Datagrid>
    </ArrayField>
  ) : null;
};

const TargetField = (props: any) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const multi = record.targets.length > 1;
  return multi ? null : props.children;
};
export default ListForm;
