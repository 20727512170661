import { ChangeEvent, useState } from "react";
import { RichTextInput } from "ra-input-rich-text";
import {
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  required,
  Create,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  maxLength,
  useTranslate,
  FormDataConsumer,
  SortPayload,
  useTheme,
  useResourceContext,
} from "react-admin";
import { checkTransformationsOrder, scheduleValidation } from "..";
import IntegrationTarget from "./TargetIntegration";
import TargetDelay from "./TargetDelay";
import { useRedirectWithParams, useS3DepositConnection } from "@/Tools/hooks";
import { clearStyleFromHtml, toUpperCase, validateFilename } from "@/Tools/helpers";
import {
  DEFAULT_TRANSFERT_MODE,
  DEFAULT_TRANSFERT_TYPE,
  DEFAULT_POLLING_TYPE,
  FILENAME_PATTERNS,
} from "./constants";
import { S3DepositSource } from "@/Components/Models/Configuration/SourceS3Deposit";
import { StandardSource } from "@/Components/Models/Configuration/SourceStandard";

const SummaryTab = () => (
  <>
    <TextInput source="name" validate={[required(), maxLength(4)]} parse={toUpperCase} />
    <TextInput source="code" validate={[required(), maxLength(6)]} parse={toUpperCase} />
    <RichTextInput source="description" validate={[required()]} parse={clearStyleFromHtml} />
    <TextInput source="requestor" validate={[maxLength(100)]} />
    <TextInput source="demand_number" validate={[maxLength(100)]} />
    <ReferenceInput source="criticity" reference="criticityType">
      <SelectInput optionText="name" validate={[required()]} />
    </ReferenceInput>
    <BooleanInput source="enabled" defaultValue={true} />
    <BooleanInput source="direct" defaultValue={false} />
  </>
);

interface SourceTabProps {
  s3DepositConnectionId: string;
}

const SourceTab = ({ s3DepositConnectionId }: SourceTabProps) => {
  const [useS3Deposit, setS3Deposit] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setS3Deposit(event.target.checked);
  };

  return (
    <>
      <BooleanInput
        source="source.use_s3_deposit"
        label={"Use S3 Deposit"}
        onChange={handleChange}
        defaultValue={useS3Deposit}
      />
      {useS3Deposit ? (
        <S3DepositSource s3DepositConnection={s3DepositConnectionId} />
      ) : (
        <StandardSource />
      )}
    </>
  );
};

const TargetTab = () => {
  const translate = useTranslate();
  const [theme] = useTheme();
  const sortable: SortPayload = { field: "name", order: "ASC" };

  return (
    <ArrayInput source="targets">
      <SimpleFormIterator className={`${theme === "dark" ? "darkTheme" : ""} multiTargetConfig`}>
        <RichTextInput
          source="description"
          validate={[required()]}
          label={translate("resources.configuration.fields.target.description")}
        />
        <ReferenceInput
          source="connection"
          reference="connection"
          label={translate("resources.configuration.fields.target.connection")}
          sort={sortable}
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <BooleanInput
          source="enabled"
          defaultValue={true}
          label={translate("resources.configuration.fields.target.enabled")}
        />
        <BooleanInput
          source="error_if_file_exists"
          defaultValue={false}
          label={translate("resources.configuration.fields.target.error_if_file_exists")}
        />
        <TextInput
          source="path"
          validate={[required()]}
          label={translate("resources.configuration.fields.target.path")}
        />
        <ReferenceInput
          source="application"
          reference="application"
          label={translate("resources.configuration.fields.target.application")}
          sort={sortable}
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <TextInput
          label={translate("resources.configuration.fields.target.wisp_group")}
          source="wisp_group"
        />
        <TextInput source="job" label={translate("resources.configuration.fields.target.job")} />
        <TextInput
          source="information"
          label={translate("resources.configuration.fields.target.information")}
        />
        <ReferenceInput
          source="pattern_file_name"
          reference="fileNamePattern"
          label={translate("resources.configuration.fields.target.pattern_file_name")}
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ scopedFormData }) =>
            scopedFormData && scopedFormData.pattern_file_name === FILENAME_PATTERNS.TRIM ? (
              <TextInput
                source="trim_character"
                validate={[required()]}
                label={translate("resources.fileNamePattern.fields.trim_character")}
              />
            ) : null
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ scopedFormData }) =>
            scopedFormData &&
            (scopedFormData.pattern_file_name === FILENAME_PATTERNS.RENAME ||
              scopedFormData.pattern_file_name === FILENAME_PATTERNS.RENAME_CHRONO ||
              scopedFormData.pattern_file_name === FILENAME_PATTERNS.RENAME_NOW) ? (
              <TextInput
                source="new_name"
                validate={[required(), validateFilename]}
                label={translate("resources.fileNamePattern.fields.new_name")}
              />
            ) : null
          }
        </FormDataConsumer>
        <NumberInput
          source="nb_files_per_transfert"
          defaultValue={-1}
          label={translate("resources.configuration.fields.target.nb_files_per_transfert")}
        />

        <TargetDelay
          source="delay"
          label={translate("resources.configuration.fields.target.delay")}
        />

        <FormDataConsumer>
          {({ formData }) =>
            formData && formData.direct === false ? (
              <TextInput
                source="schedule_expression"
                validate={[required(), scheduleValidation]}
                label={translate("resources.configuration.fields.source.schedule_expression")}
              />
            ) : null
          }
        </FormDataConsumer>

        <IntegrationTarget
          source="integration"
          label={translate("resources.configuration.fields.target.integration")}
        />

        <ArrayInput
          source="transformations"
          label={translate("resources.configuration.fields.target.transformations")}
          validate={[checkTransformationsOrder]}
        >
          <SimpleFormIterator>
            <ReferenceInput
              source=""
              reference="transformationType"
              label="resources.configuration.fields.transformation"
            >
              <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const CreateForm = (props: any) => {
  const resource = useResourceContext();
  const s3DepositConnection = useS3DepositConnection();
  const redirect = useRedirectWithParams(resource!, [["from", "create"]]);

  const formatIntegration = (integration: any) => {
    switch (integration.type) {
      case "WRITE_FILE":
        return {
          use_integration: integration.use_integration,
          type: integration.type,
          template: integration.template,
          parameters: {
            connection: integration.parameters!.connection,
            path: integration.parameters.path,
            file_name: integration.parameters.file_name,
            content_pattern: integration.parameters.content_pattern,
            use_target_connection: integration.parameters.use_target_connection,
          },
        };
      case "START_FLOW":
        return {
          use_integration: integration.use_integration,
          type: integration.type,
          parameters: {
            flow_id: integration.parameters!.flow_id,
          },
        };
      default:
        return integration;
    }
  };

  const cleanFields = (data: any) => {
    const useSourceTransformations = data.source.transformations ? true : false;
    const useSourceMoveAfter = data.source.transfert_type === "MOVE_AFTER" ? true : false;
    if (!useSourceTransformations) delete data["source"]["transformations"];
    if (!useSourceMoveAfter) delete data["source"]["transfert_path"];
    if (!data.source.use_s3_deposit) {
      return {
        ...data,
        source: {
          ...data.source,
          use_s3_deposit: false,
        },
        targets: data.targets.map((target: any) => {
          const useTargetTransformations = target.transformations ? true : false;
          const useIntegration = target.integration.use_integration ? true : false;
          if (!useTargetTransformations) delete target["transformations"];
          return {
            ...target,
            integration: {
              ...(useIntegration
                ? formatIntegration(target.integration)
                : { use_integration: false }),
            },
          };
        }),
      };
    }

    const emptyFilters = {
      unitType: "",
      unit: 0,
    };
    return {
      ...data,
      source: {
        ...data.source,
        use_s3_deposit: data.source.use_s3_deposit,
        connection: s3DepositConnection,
        path: "",
        regex: "",
        transfert_type: DEFAULT_TRANSFERT_TYPE,
        transfert_mode: DEFAULT_TRANSFERT_MODE,
        nb_files_per_transfert: "-1",
        polling: DEFAULT_POLLING_TYPE,
        filters_enabled: false,
        filters: emptyFilters,
      },
      targets: data.targets.map((target: any) => {
        const useTargetTransformations = target.transformations ? true : false;
        const useIntegration = target.integration.use_integration ? true : false;
        if (!useTargetTransformations) delete target["transformations"];
        return {
          ...target,
          integration: {
            ...(useIntegration
              ? formatIntegration(target.integration)
              : { use_integration: false }),
          },
        };
      }),
    };
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess: redirect }} transform={cleanFields}>
      <TabbedForm warnWhenUnsavedChanges>
        <FormTab label="Summary">
          <SummaryTab />
        </FormTab>
        <FormTab label="Source">
          <SourceTab s3DepositConnectionId={s3DepositConnection} />
        </FormTab>

        <FormTab label="Target">
          <TargetTab />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CreateForm;
